<template>
  <v-container id="strategies" fluid tag="section">

    <v-row>
      <v-col>
        <v-data-table dense dark width="100%" v-model="selected" :single-select="singleSelect" show-select
                      :headers="headers" :items="strategy_list" class="elevation-1">

          <template v-slot:top>
            <v-row justify="start">
              <v-col class="ma-5" cols="12">
                <v-btn @click="tryToNew">New</v-btn>
                <v-btn :disabled="disabled" :class="'ml-3 ' + btnClass">Start</v-btn>
                <v-btn :disabled="disabled" :class="'ml-2 ' + btnClass">Stop</v-btn>
              </v-col>
            </v-row>
          </template>

          <template v-slot:item.strategyName="{ item }">
            <router-link
                :to="`/pages/strategies/details/${item.strategyName}?instanceId=${item.instanceId}${item.accountType !== undefined && item.accountType !== '' ? '&accountType=' + item.accountType : ''}`">
              {{
                item.strategyName
              }}
            </router-link>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <new-strategy-dlg :dialogFlag="dlgEdit" :cancelFunc="closeEditDlg"></new-strategy-dlg>

  </v-container>
</template>

<script>
import {get_strategy_list,} from "@/api";

import NewStrategyDlg from "@/views/dashboard/component/NewStrategyDlg";

export default {
  name: 'StrategyList',

  components: {
    NewStrategyDlg,
  },
  mounted: async function () {
    await this.initData()

    this.timer = setInterval(async () => {
      await this.initData()
    }, 3000)

  },

  destroyed() {
    clearInterval(this.timer);
  },

  watch: {
    selected(val) {
      this.disabled = !(val !== undefined && val !== null && val.length > 0);
    },
  },

  computed: {
    color() {
      return this.$vuetify.theme.dark ? "white" : "black";
    },
  },

  data() {
    return {
      timer: -1,
      dlgEdit: false,
      disabled: true,
      selected: [],
      btnClass: "ma-0 white--text",
      singleSelect: false,
      headers: [
        {text: "Strategy Name", value: "strategyName"},
        {text: "InstanceId", value: "instanceId"},
        {text: "Status", value: "strategyStatus"},
        {text: "Gateways", value: "gateways"},
        {text: "Params", value: "params"},
      ],
      width: ["5%", "5%", "5%", "5%", "80%"],
      accountUpdate: {},
      orderTradeUpdate: {},
      strategy_list: [],
    };
  },

  methods: {

    async initData() {
      this.strategy_list = await get_strategy_list();
    },

    tryToNew() {
      this.dlgEdit = true;
    },

    closeEditDlg() {
      this.dlgEdit = false;
    },

  },
};
</script>
