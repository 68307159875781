<template>
  <v-dialog persistent v-model="dialogFlag" max-width="400px">
    <v-card>

      <v-card-title>
        <span class="text-h5">New Strategy</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <selector-single title="Strategy" :items="strategies" :onChange="onStrategyChange"></selector-single>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-text-field v-model="instanceId" label="InstanceId"></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <selector-multi title="Gateways" :items="gatewayList" :onChange="onGatewaysChange"></selector-multi>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="cancelFunc"> Cancel </v-btn>
        <v-btn :disabled="isAddDisabled" color="blue darken-1" text @click="addNewStrategy"> Add </v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>
</template>

<script>

import { Strategies } from '@/constants';
import { get_account_list, create_strategy, } from "@/api";
import { tradeMixin } from "@/mixins";
import SelectorSingle from '@/views/dashboard/component/SelectorSingle';
import SelectorMulti from '@/views/dashboard/component/SelectorMulti';

export default {
  name: "NewStrategyDlg",

  mixins: [tradeMixin],

  components: {
    SelectorSingle,
    SelectorMulti,
  },

  props: {
    dialogFlag: {
      type: Boolean,
      default: false,
    },
    cancelFunc: {
      type: Function,
    },
  },

  mounted: async function () {
    const accList = await get_account_list();
    this.gatewayList = accList.gateways;
  },

  methods: {
    onStrategyChange(ev) {
      this.strategyName = ev;
    },
    onGatewaysChange(ev) {
      this.gateways = ev;
    },
    addNewStrategy() {
      console.log('addNewStrategy', this.strategyName, ' ', this.instanceId, ' ', this.gateways);

      let isOnTimer = undefined

      if (this.strategyName === 'DualPerpetualStrategy' || this.strategyName === 'NewDualPerpetualStrategy'
       || this.strategyName === 'SpotAlgoTradingStrategy') {
        isOnTimer = true
      }

      create_strategy({ strategyName: this.strategyName, instanceId: this.instanceId, isOnTimer, gateways: this.gateways, })
      
      this.cancelFunc();
    }
  },

  computed: {
        isAddDisabled() {
            return this.strategyName.trim() === '' || this.instanceId.trim() === '' || this.gateways.length <= 0;
        },
    internalDialogFlag: {
      get: function () {
        return this.dialogFlag;
      },
      set: function (newVal) { },
    },
  },

  data: function () {
    return {
      strategies: Strategies,
      strategyName: '',
      instanceId: '',
      gatewayList: [],
      gateways: [],
    };
  }
};
</script>
